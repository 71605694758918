import { Flex } from '@chakra-ui/react'
import { GeometryState, StateProps, WebsiteState } from 'lib/state'
import OrderNowButton from './buttons/order-now-button'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import { MeshMaker } from 'lib/mesh-maker'
import { discountPercentageConfig } from 'lib/constants'

export default function PriceDisplay({
  websiteState,
  geometryState,
  borderColor,
}: {
  websiteState: WebsiteState
  geometryState: GeometryState
  borderColor: string
}) {
  const { fullPrice, discountedPrice } = websiteState.boundingBox.reduce(
    (accumulator, currentValue) => {
      const result = MeshMaker.getPrice(
        currentValue.minVector,
        currentValue.maxVector
      )

      accumulator.fullPrice += result.fullPrice
      accumulator.discountedPrice += result.discountedPrice
      return accumulator
    },
    { fullPrice: 0, discountedPrice: 0 }
  )

  const fullPriceDisplay = fullPrice.toString()
  const discountedPriceDisplay = discountedPrice.toString()

  const [fullPriceDisplayDollars, fullPriceDisplayCents] =
    fullPriceDisplay.split('.')

  const [discountedPriceDisplayDollars, discountedPriceDisplayCents] =
    discountedPriceDisplay.split('.')

  return (
    <Flex
      fontFamily={'heading'}
      color={pickTextColorBasedOnBgColor(geometryState.color)}
    >
      <Flex
        direction={{ base: 'row', md: 'column' }}
        alignItems={'flex-end'}
        fontSize={{ base: '7px', md: '12px' }}
      >
        <Flex direction={'column'}>
          <Flex
            alignItems={'flex-end'}
            fontSize={'5em'}
            lineHeight={'60%'}
            paddingTop={'10%'}
          >
            <Flex>€{discountedPriceDisplayDollars}</Flex>
            <Flex fontSize={'0.5em'}> {discountedPriceDisplayCents}</Flex>
          </Flex>

          <Flex
            fontSize={'2.5em'}
            textDecoration={'line-through'}
            opacity={0.5}
            justifyContent={'center'}
          >
            €{fullPriceDisplay}
          </Flex>

          <Flex
            fontSize={'1.2em'}
            direction={'row'}
            justifyContent={'center'}
            opacity={0.5}
          >
            {discountPercentageConfig}
            {'% Beta Tester Discount'}
          </Flex>
        </Flex>
        <OrderNowButton
          color={geometryState.color}
          discountedPrice={discountedPrice}
          websiteState={websiteState}
          geometryState={geometryState}
          borderColor={borderColor}
        />
      </Flex>
    </Flex>
  )
}
