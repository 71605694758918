import { modelTypeFlags, modelTypes } from 'lib/constants'
import { StateProps } from 'lib/state'
import IneEditor from './ine-editor'
import SketchEditor from './sketch-editor'
import SphereScaler from './sphere-scaler'
import SplineEditor from './spline-editor'
import SplineScaler from './spline-scaler'
import TaperScaler from './taper-scaler'

export default function ShapeDefiner({
  geometryState,
  websiteState,
}: StateProps) {
  return (
    <>
      {geometryState.modelType.flags.includes(modelTypeFlags.isSplineBased) &&
        websiteState.hasMounted && (
          <SplineEditor
            geometryState={geometryState}
            websiteState={websiteState}
          />
        )}

      {geometryState.modelType.flags.includes(modelTypeFlags.isIneBased) &&
        websiteState.hasMounted && (
          <IneEditor
            geometryState={geometryState}
            websiteState={websiteState}
          />
        )}

      {geometryState.modelType.flags.includes(modelTypeFlags.isSketchBased) &&
        websiteState.hasMounted && (
          <SketchEditor
            geometryState={geometryState}
            websiteState={websiteState}
          />
        )}

      {geometryState.modelType.flags.includes(
        modelTypeFlags.shouldHaveSplineScaler
      ) && (
        <SplineScaler
          geometryState={geometryState}
          websiteState={websiteState}
          minHeight={0.2}
        />
      )}

      {geometryState.spline.modelType.id == modelTypes.bubble.id && (
        <TaperScaler
          geometryState={geometryState}
          websiteState={websiteState}
        />
      )}

      {geometryState.spline &&
        websiteState.sidebarIsOpen &&
        geometryState.modelType.id == modelTypes.bubble.id &&
        websiteState.selectedSphere != -1 && (
          <SphereScaler
            geometryState={geometryState}
            websiteState={websiteState}
          />
        )}
    </>
  )
}
