import { Button, Flex } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { GeometryState, WebsiteState } from 'lib/state'
import { saveToDatabase } from './copy-url-button'
import SpinnerIcon from '../icons/spinner'
import { useState } from 'react'
import { event } from 'lib/gTag'

const stripePromise = () =>
  loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)

interface OrderNowButtonPropsType {
  color: string
  discountedPrice: number
  geometryState: GeometryState
  borderColor: string
}

export default function OrderNowButton({
  color,
  discountedPrice,
  geometryState,
  borderColor,
  websiteState,
}: {
  color: string
  geometryState: GeometryState
  borderColor: string
  discountedPrice: number
  websiteState: WebsiteState
}) {
  const [isLoading, setIsLoading] = useState(false)

  const sessionCaller = async () => {
    setIsLoading(true)
    const { uuid, res } = await saveToDatabase(websiteState)

    if (res.status != 200) {
      alert(
        'We were unable to save your design to the database.\nPlease check your Internet Connection'
      )
      throw 'URL was not uploaded to the database'
    }

    try {
      const stripe = await stripePromise()
      const checkoutSession = await axios.post('/api/checkout_session', {
        price: discountedPrice,
        stateId: uuid,
      })

      const result = await stripe!.redirectToCheckout({
        sessionId: checkoutSession.data.id,
      })

      if (result.error) {
        alert(result.error.message)
      }

      setIsLoading(false)
    } catch (err) {
      alert(
        'We were not able to complete your request.\nWe apologize for the inconvenience'
      )
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <Flex
      onClick={() => {
        if (!isLoading) sessionCaller()

        event({
          action: 'Order Now Button Click',
          params: {
            search_term: 'Order Now',
          },
        })
      }}
      width={'100%'}
      //@ts-ignore
      as={motion.div}
      whileHover={{
        textDecoration: 'underline',
      }}
      whileTap={{ scale: 0.9 }}
      borderRadius={'0.5em'}
      marginTop={'1em'}
      marginLeft={{ base: '1em', md: '0' }}
      backgroundColor={'#' + color}
      shadow={'lg'}
      cursor={'pointer'}
      color={pickTextColorBasedOnBgColor(color)}
      justifyContent={'center'}
      alignItems={'center'}
      fontWeight={'bold'}
      borderWidth={'2px'}
      borderColor={borderColor}
    >
      <Flex
        fontSize={{ base: '3em', md: '1.5em' }}
        paddingY={{ base: '0.1em', md: '0.3em' }}
        paddingX={'0.5em'}
      >
        {isLoading ? (
          <Flex fontSize={'0.5em'}>
            <SpinnerIcon />
          </Flex>
        ) : (
          <>Order Now</>
        )}
      </Flex>
    </Flex>
  )
}
